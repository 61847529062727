@charset "UTF-8";

@import "../mixin";

.top-section {
  &--pic {
    position: absolute;
    left: 549px;
    top: -9px;
    @include sp {
      width: 320px;
      height: 320px;
      left: calc(50% - 160px);
      top: -321px;
    }
  }
}